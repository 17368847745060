import $ from 'jquery';
import 'focus-visible';

window.$ = $;
// import { gsap } from 'gsap';


$(() => {

	// Header navigation 

	$('.js-icon-mobile-nav').click(function () {
		$('header').toggleClass('js-nav-open');
		$(".js-menu").fadeToggle(100);
	});
	// Nav menu mobile
	if ($(window).width() < 992) {
		$('.js-item-nav span').click(function (event) {
			$(this).siblings('.js-submenu').fadeToggle(300).toggleClass('menu-contents-active');
			$('.js-submenu').not($(this).siblings('.js-submenu')).hide().removeClass('menu-contents-active');
		});
	}
	// Nav menu desktop
	else {
		$('.js-item-nav > span').hover(function (event) {
			$('header').addClass('js-open-nav-menu');
			$(this).siblings('.js-submenu').addClass('menu-contents-active').fadeIn();
			$('.js-submenu').not($(this).siblings('.js-submenu')).hide().removeClass('menu-contents-active');
		});
		$('.js-menu').mouseleave(function (event) {
			$('header').removeClass('js-open-nav-menu');
			$('.js-submenu').hide().removeClass('menu-contents-active');
		});

		// Accesibility 
		$('.js-item-nav > span').focus(function() {
			$('header').addClass('js-open-nav-menu');
			$(this).siblings('.js-submenu').addClass('menu-contents-active').fadeIn();
			$('.js-submenu').not($(this).siblings('.js-submenu')).hide().removeClass('menu-contents-active');
		});

		$(document).on('keydown', function(event) {
			 if (event.key == "Escape") {
				$('header').removeClass('js-open-nav-menu');
				$('.js-submenu').hide().removeClass('menu-contents-active');
			}
		});

		$('.main-search').on('keydown', function(event) {
			if (event.key == "Tab") {
				$('header').removeClass('js-open-nav-menu');
				$('.js-submenu').hide().removeClass('menu-contents-active');
			}
		});
	}

	$('.side-element').click(function() {
		$(this).children('.collapsible-content').slideToggle();
		$(this).toggleClass('opened');
	});



});

